import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay} from 'swiper';
import 'swiper/css';
import {OrderContext} from '../context/OrderContext';
import ProductCard from './Cards/ProductCard';


const ThankyouCarousel = () => {

    const {products} = React.useContext(OrderContext); 
    const ourProducts = products.filter((prod) => prod.quantity === 0)

    return (
        <div className="thank-you-carousel">
            <Swiper
                modules={[Autoplay]}
                autoplay={true}
                spaceBetween={10}
                slidesPerView={1}
                breakpoints={{
                    1900: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    320: {
                        slidesPerView: 2,
                    },
                }}
            >
                {
                    ourProducts.map((product,i)=>(
                        <SwiperSlide key={i}>
                            <ProductCard
                                product={product}
                            />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}


export default ThankyouCarousel;