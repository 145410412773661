
import React from "react";

export const thankyouAutomaticData = {
    type:"alta-automatica",
    title:"¡Te damos la bienvenida a la familia IVESS!",
    subtitle:<p className="text-regular text-center text-lg-start text-dark-grey">Cuando te visitemos para entregarte tu primer pedido, coordinaremos un día y horario de visita. Nuestras visitas son semanales, no hace falta que hagas tu pedido con anterioridad.<br></br>Aboná tus compras en efectivo o con tarjeta de débito, ya sea ingresando <a className="text-cd-primary" href="https://micuenta.rosmino.com.ar" rel="noreferrer" target="_blank"><u>acá</u></a> (con el mail como usuario) o desde nuestro Whatsapp <a className="text-cd-primary" href="http://wa.link/breru7 " target="_blank" rel="noreferrer"><u>+5491139267357</u></a></p>
    
}

export const thankyouManualData = {
    type:"alta-asesor",
    title:"¡Muchas gracias por tu interés!",
    subtitle:"Próximamente un agente de IVESS se estará comunicando con vos."
}

