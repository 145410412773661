import * as React from "react";
import {Link} from 'gatsby';
import '../assets/app.scss';
import Seo from "../components/seo";
import AppLayout from '../components/AppLayout';
import ThankyouCarousel from "../components/ThankyouCarousel";
import {thankyouAutomaticData} from '../data/thankyou';
import { seoAutomaticRegistrationTY } from "../data/seo";
import {Helmet} from "react-helmet";


const ThankYouAutomaticRegistration = ({data}) => {

    const {title, subtitle} = thankyouAutomaticData;

    return (
        <AppLayout>
            <div className="thank-you mt-5">

            <Seo
                title={seoAutomaticRegistrationTY.title}
                description={seoAutomaticRegistrationTY.description}
            />

            <Helmet
                bodyAttributes={{
                    class: 'bk-extra-light-grey'
                }}
            />

                <div className="container-custom">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6">
                            <div className="form-container-thankyou d-flex flex-column align-items-center mb-4">
                                <div className="d-flex flex-column flex-lg-row align-items-center">
                                    <div className="d-flex flex-column justify-content-center order-2 order-xl-1">
                                        <h1 className="fs-20 text-center text-lg-start text-bold mb-4">{title}</h1>
                                        {subtitle}
                                    </div>
                                    <img src="/success.svg" className="order-1 order-xl-2" alt="icon" />
                                </div>
                                <Link to="/" className="btn-cd-primary mt-5">Volver al inicio</Link>
                            </div>
                            <div className="form-container-thankyou">
                                <h2 className="fs-20 text-bold mb-4">Nuestros productos</h2>
                                <ThankyouCarousel/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default ThankYouAutomaticRegistration;